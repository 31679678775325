<template>
  <div class="card card-body shadow-sm">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.KYY_TABLE_ORDER_BY_MONTHS"
        :isDisableButton="isDisableButton"
        @getTotal="getDataTotal"
      >
        <template v-slot:table-menu-center>
          <div class="row mt-2 pb-5 c_total_area">
            <div class="col-xl-4 col-lg-4 col-sm-4 pt-3"
                 style="margin-left: 7px; margin-right: -7px; padding-right: 0; padding-left: 0;"
            >
              <div class="c_background_gray c_border_radius_1 p-4">
                <label>Tổng đơn</label>
                <h3 class="c_text_align_center c_font_size_40px">{{ totalRecord ? totalRecord : '--' }}</h3>
              </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-sm-1 pl-0"></div>
            <div class="col-xl-7 col-lg-7 col-sm-7 pt-3"
                 style="padding-left: 4px;"
            >
              <fieldset class="c_chart_area">
                <div class="row mt-2 pb-2 pl-3">
                  <div class="col-xl-12 col-lg-12 col-sm-12 pl-0 font-weight-bold">{{ $t('table_order.3month') }}</div>
                </div>
                <div id="chart" ref="chart" style="width: 100%;height:100%; min-height: 300px;"></div>
              </fieldset>
            </div>
          </div>
        </template>

        <template v-slot:body-cell-detail="props">
          <td class="app-align-middle text-center app-p-none c_w_10_percent">
            <button
              @click="handleBtnDetailClick(props.row)"
              class="btn btn-primary"
            >
              {{ $t("sale.detail") }}
            </button>
          </td>
        </template>

        <template v-slot:body-cell-month="props">
          <td class="text-center">
            <p class="p- m-0">{{ __formatDate(props.row.month, 'YYYY/MM') }}</p>
          </td>
        </template>

        <template v-slot:body-cell-number_item="props">
          <td class="text-center">
            <p class="p-0 m-0">{{ props.row.number_item }}
              <span class="percentage" v-if="needToShowPercentage(props.row.month, monthSelect)">{{ formatAsPercentage(props.row.number_item / totalRecord) }}</span>
            </p>
          </td>
        </template>
      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
import ListTableOrder from "@/pages/admin/kyy_table_order/ListTableOrder";
import {ENDPOINT} from "../../../constants/api";
import {MonthPickerInput} from 'vue-month-picker'
import moment, { months } from 'moment'

import * as echarts from 'echarts';
import { rest } from "lodash";

var myChart;
export default {
  name: "SaleListPage",
  components: {
    "app-basic-table": ListTableOrder,
    MonthPickerInput,
  },
  data() {
    return {
      monthList: [
        moment().subtract(2, 'months').format('M月'),
        moment().subtract(1, 'months').format('M月'),
        moment().format('M月')
      ],
      monthSelect: moment().format('YYYY/MM/01'),
      columns: [
        {name: "month", label: this.$t('common.month'), sortable: true, textAlign: 'text-center', class: 'c_w_20'},
        {name: "restaurant_name", label: this.$t('restaurant.name'), sortable: true, textAlign: 'text-left', class: 'c_w_40'},
        {name: "number_item", label: this.$t('table_order.amount_record'), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
      ],
      endPoint: '',
      totalPrice: 0,
      totalRecord: 0,
      currentMonth: {
        price: 0,
        record: 0
      },
      prevMonth: {
        price: 0,
        record: 0
      },
      multiPrevMonth: {
        price: 0,
        record: 0
      },
      isDisableButton : false
    };
  },
  methods: {
    createChart() {
      let chartDom = this.$refs.chart
      myChart = echarts.init(chartDom);
      let option;
      const colors = ['#057ED3', '#77B008'];
      option = {
        color: colors,
        sizeAutoCapable: true,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          right: '15%',
          left: '20%',
        },
        toolbox: {
          feature: {
            dataView: {show: false, readOnly: false},
            restore: {show: false},
            saveAsImage: {show: false}
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            // prettier-ignore
            data: this.monthList
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: this.$t("table_order.amount_record"),
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1]
              }
            },
            axisLabel: {
              formatter: '{value}'
            }},
        ],
        series: [
          {
            name: this.$t("table_order.quantity"),
            type: 'line',
            color: [colors[1]],
            yAxisIndex: 0,
            data: [
              this.multiPrevMonth.record, this.prevMonth.record, this.currentMonth.record
            ]
          }
        ]
      };

      option && myChart.setOption(option);

    },
    handlerApartmentNameClick(entry) {
      let routeApartment = this.$router.resolve({
        name: this.ROUTES.ADMIN.APARTMENT_EDIT,
        params: {id: entry.restaurant_id}
      });
      window.open(routeApartment.href, '_blank');
    },
    handleBtnDetailClick(entry) {
      this.$router.push({
        name: this.ROUTES.ADMIN.KYY_STUDENT_ORDER_LIST,
        query: {
          "filters.restaurant_id.equal": entry.restaurant_id,
          "filters.month.equal": this.monthSelect ?? moment().format('YYYY/MM/01'),
        },
      });
    },
    async getDataTotal(params) {
      moment.locale('vi')
      this.monthList = [
        moment(params.month).subtract(2, 'months').format('MMMM'),
        moment(params.month).subtract(1, 'months').format('MMMM'),
        moment(params.month).format('MMMM')
      ]
      this.monthSelect = moment(params.month).format('YYYY/MM/01')
      this.resetTotalData();
      this.isDisableButton = true
      await this.$request
        .get(ENDPOINT.KYY_TABLE_ORDER_BY_MONTHS, {month: params.month, restaurant_id: params.restaurant_id})
        .then((res) => {
          let totalByMonth;
          let groupByMonth = res.data.data;
          if (params.restaurant_id == 'all') {
            // group by month
            groupByMonth = Object.values(
              res.data.data.reduce((acc, item) => {
                if (!acc[item.month]) {
                  acc[item.month] = {
                    number_item: 0,
                    price: 0,
                    month: item.month
                  };
                }
                acc[item.month].number_item += item.number_item;
                return acc;
              }, {})
            );
          }
          groupByMonth.map((item) => {
            if (item.month === moment(params.month).format('YYYYMM')) {
              this.totalRecord += item.number_item
              this.currentMonth = {
                record: item.number_item,
                amount: 0
              }
            } else if (item.month === moment(params.month).subtract(1, 'months').format('YYYYMM')) {
              this.prevMonth = {
                record: item.number_item,
                amount: 0
              }
            } else if (item.month === moment(params.month).subtract(2, 'months').format('YYYYMM')) {
              this.multiPrevMonth = {
                record: item.number_item,
                amount: 0
              }
            }
          })
          this.totalRecord.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
          this.createChart()
          this.isDisableButton = false
        })
    },
    resetTotalData() {
      this.totalPrice = 0
      this.totalRecord = 0
      this.currentMonth = {
        record: 0,
        price: 0
      }
      this.prevMonth = {
        record: 0,
        price: 0
      }
      this.multiPrevMonth = {
        record: 0,
        price: 0
      }
    },
    formatAsPercentage(value) {
      if (isNaN(value)) return '0%';
      return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
    },
    needToShowPercentage(month, monthSelect) {
      return month === moment(monthSelect).format('YYYYMM');
    }
  },
  mounted: async function () {
  },
}
window.addEventListener('resize',function(){
  myChart.resize();
})
</script>

<style scoped>
.percentage {
  font-size: 0.8em; /* Smaller font size */
  color: #4CAF50; /* Green color */
  font-weight: bold; /* Bold text */
  background-color: #f0f0f0; /* Light grey background */
  padding: 2px 5px; /* Padding around the text */
  border-radius: 3px; /* Rounded corners */
}
</style>